import { useReducer, useCallback } from 'react';
import { MessageProps } from '@chatscope/chat-ui-kit-react';
import { v4 as uuidv4 } from 'uuid';
import { WidgetState, WidgetAction } from './types';
import {
  DEFAULT_STATE,
  INIT_CHAT, UPDATE_ATTACHMENT_IDS,
  SEND_INITIAL_PROMPT, SEND_INITIAL_PROMPT_SUCCESS, SEND_INITIAL_PROMPT_ERROR,
  SEND_MESSAGE, SEND_MESSAGE_STREAM_SUCCESS, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_ERROR,
} from './constants';
import { createMessage, getMessageId } from './helpers';
import { MessageDirection } from '@chatscope/chat-ui-kit-react/src/types/unions';

function reducer (state: WidgetState = DEFAULT_STATE, action: WidgetAction): WidgetState {
  switch (action.type) {
    case INIT_CHAT:
      return {
        ...DEFAULT_STATE,
        isLoading: false,
        messages: action.payload.initMessages ?? [],
        chatSessionId: uuidv4(),
        documentRevisionId: action.payload.documentRevisionId,
        attachmentIds: action.payload.attachmentIds,
      }
    case UPDATE_ATTACHMENT_IDS:
      return {
        ...state,
        attachmentIds: action.payload.attachmentIds,
      }
    case SEND_INITIAL_PROMPT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case SEND_INITIAL_PROMPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case SEND_MESSAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
        messages: [
          ...state.messages,
          action.payload.requestMessage,
        ],
      }
    case SEND_MESSAGE_STREAM_SUCCESS:
    case SEND_MESSAGE_SUCCESS:
      const isFinished = action.type === SEND_MESSAGE_SUCCESS;
      const actionMessageId = getMessageId(action.payload.responseMessage);

      const messages = state.messages.find(item => getMessageId(item) === actionMessageId)
        ? state.messages.map((item) => {
            const itemId = getMessageId(item);

            return createMessage({
              id: itemId,
              type: item.type,
              message: itemId === actionMessageId
                ? (action.payload.responseMessage?.model?.message as string) + (!isFinished ? '...' : '')
                : (item.model?.message as string),
              direction: item.model?.direction as MessageDirection,
            });
          })
        : [
            ...state.messages,
            createMessage({
              id: getMessageId(action.payload.responseMessage),
              type: action.payload.responseMessage.type,
              message: (action.payload.responseMessage?.model?.message as string) + (!isFinished ? '...' : ''),
              direction: action.payload.responseMessage.model?.direction as MessageDirection,
            }),
          ];

      return {
        ...state,
        isLoading: !isFinished,
        error: null,
        messages,
      }
    case SEND_INITIAL_PROMPT_ERROR:
    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}

export default function useWidgetState () {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  const initChat = useCallback((payload: { initMessages?: MessageProps[], documentRevisionId?: string | null, attachmentIds?: string[] | null }) => {
    dispatch({ type: INIT_CHAT, payload });
  }, []);

  const updateAttachmentIds = useCallback((payload: { attachmentIds?: string[] | null }) => {
    dispatch({ type: UPDATE_ATTACHMENT_IDS, payload });
  }, []);

  const sendInitialPrompt = useCallback(() => {
    dispatch({ type: SEND_INITIAL_PROMPT });
  }, []);

  const sendInitialPromptSuccess = useCallback(() => {
    dispatch({ type: SEND_INITIAL_PROMPT_SUCCESS });
  }, []);

  const sendInitialPromptError = useCallback((error: string) => {
    dispatch({ type: SEND_INITIAL_PROMPT_ERROR, payload: { error } });
  }, []);

  const sendMessage = useCallback((requestMessage: MessageProps) => {
    dispatch({ type: SEND_MESSAGE, payload: { requestMessage } });
  }, []);

  const sendMessageStreamSuccess = useCallback((responseMessage: MessageProps) => {
    dispatch({ type: SEND_MESSAGE_STREAM_SUCCESS, payload: { responseMessage } });
  }, []);

  const sendMessageSuccess = useCallback((responseMessage: MessageProps) => {
    dispatch({ type: SEND_MESSAGE_SUCCESS, payload: { responseMessage } });
  }, []);

  const sendMessageError = useCallback((error: string) => {
    dispatch({ type: SEND_MESSAGE_ERROR, payload: { error } });
  }, []);

  const actions = {
    initChat,
    updateAttachmentIds,
    sendInitialPrompt,
    sendInitialPromptSuccess,
    sendInitialPromptError,
    sendMessage,
    sendMessageStreamSuccess,
    sendMessageSuccess,
    sendMessageError,
  }

  return [state, actions] as const;
}
