import React, { forwardRef } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import * as Icon from '../Icon';
import { Texts } from '../../constants';
import useStyles from './styles';

interface WidgetButtonProps {
  btnRef: React.RefObject<HTMLButtonElement>
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const WidgetButton: React.FC<WidgetButtonProps> = forwardRef<HTMLButtonElement, WidgetButtonProps>((
  props,
) => {
  const { onClick, btnRef } = props;
  const classes = useStyles();

  return (
    <Tooltip title={<span className={classes.tooltip}>{Texts.dialogTitle}</span>}>
      <IconButton
        ref={btnRef}
        onClick={onClick}
        data-cy="widget-button"
        size="medium"
        className={classes.button}
      >
        <Icon.Stars />
      </IconButton>
    </Tooltip>
  );
});

export default WidgetButton;
