import { createStyles, makeStyles } from '@material-ui/core';
import { Colors } from '../../constants';

export default makeStyles((theme) => createStyles({
  list: {
    height: '100%',
    width: '100%',

    '& .scrollbar-container': {
      padding: theme.spacing(0, 2)
    },
    '& .cs-message': {
      overflowX: 'auto',
    },
  },
  message: {
    '& .cs-message__text-content': {
      whiteSpace: 'normal',
    },
    '& .cs-message__text-content > *:first-child': {
      marginTop: 0,
    },
    '& .cs-message__text-content > *:last-child': {
      marginBottom: 0,
    },
    '& .cs-message__text-content table': {
      border: '1px solid black',
      borderCollapse: 'collapse',
    },
    '& .cs-message__text-content table th': {
      border: '1px solid black',
      borderCollapse: 'collapse',
    },
    '& .cs-message__text-content table td': {
      borderRight: '1px solid black',
      borderCollapse: 'collapse',
    },
    '& .cs-message__text-content ol': {
      paddingLeft: 15,
    },
    '& .cs-message__text-content ul': {
      paddingLeft: 15,
    },
  },
  outgoingMessage: {
    '& .cs-message__content': {
      backgroundColor: Colors.darkBlue,
      color: Colors.white,
    },
  },
  incomingMessage: {
    '& .cs-message__content': {
      backgroundColor: Colors.lightBlue,
    },
  },
  typing: {
    left: `${theme.spacing(2)}px !important`,
  },
}));
