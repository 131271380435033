import React, { useRef, useState, useEffect } from 'react';
import WidgetButton from '../../components/WidgetButton';
import WidgetDialog from '../../components/WidgetDialog';
import useStyles from './styles';
import useWidgetState from './reducer';
import { apiCall, delay } from '../../utils';
import { createMessage } from './helpers';
import { MESSAGE_STREAM_DELAY, MESSAGE_FETCH_DELAY } from './constants';
import { WidgetProps, AskEnlilCompletedResponse, AskEnlilResponse, AskEnlilResponseStatus, D2MWidgetProps, TMWidgetProps, FetchAskEnlilOptions } from './types';

const Widget: React.FC<WidgetProps> = (props) => {
  const { appName, companyId, userName, token, apiPath } = props;
  const { documentRevisionId: propDocumentRevisionId, attachmentIds: propAttachmentId } = props as Partial<D2MWidgetProps>;
  const { initialPrompt } = props as Partial<TMWidgetProps>;

  const classes = useStyles({ widgetPosition: null });
  const btnRef = useRef<HTMLButtonElement>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [dialogPosition, setDialogPosition] = useState<DOMRect>();
  const [{ isLoading, messages, chatSessionId, documentRevisionId, attachmentIds }, actions] = useWidgetState();
  const initialChatSessionIdRef = useRef(chatSessionId);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    handleNewChat();
  }, [appName, companyId, initialPrompt]);

  useEffect(() => {
    actions.updateAttachmentIds({ attachmentIds: propAttachmentId })
  }, [propAttachmentId]);

  const handleNewChat = () => actions.initChat({
    documentRevisionId: propDocumentRevisionId,
    attachmentIds: propAttachmentId,
  });

  useEffect(() => {
    initialChatSessionIdRef.current = chatSessionId;

    if (!chatSessionId) {
      return;
    }

    (async () => {
      if (!initialPrompt || await sendInitialPrompt(initialPrompt)) {
        actions.sendMessageSuccess(
          createMessage({
            type: 'text',
            direction: 'incoming',
            message: `Hi, **${userName}**! What would you like to ask me?`,
          })
        );
      }
    })();
  }, [chatSessionId]);

  const handleWidgetButtonClick = () => {
    if (!btnRef?.current) {
      return
    }

    const rect = btnRef.current.getBoundingClientRect();

    setDialogPosition(rect);
    setIsOpened(isOpened => !isOpened);
  };

  const handleWidgetClose = () => {
    setIsOpened(false)
  };

  const fetchAskEnlil = async (options: FetchAskEnlilOptions): Promise<AskEnlilCompletedResponse> => {
    const { message, messageSessionId, contextAction } = options;

    if (initialChatSessionIdRef.current !== chatSessionId) {
      throw new Error('Chat session has changed. Stopping recursion.');
    }

    const response = await apiCall<AskEnlilResponse>({
      url: `${apiPath}/e_ask_enlil`,
      method: 'POST',
      token,
      sessionId: messageSessionId,
      payload: {
        chat_session_id: chatSessionId,
        application_id: appName,
        company_id: companyId,
        document_revision_id: documentRevisionId,
        attachment_ids: attachmentIds,
        prompt: message,
        context_action: contextAction,
        source_time: Math.trunc(new Date().getTime() / 1000),
      }
    })

    if (response.status === AskEnlilResponseStatus.Completed) {
      return response
    }

    if (response.status === AskEnlilResponseStatus.Streaming) {
      const responseMessage = createMessage({
        id: response.message_id,
        message: response.text,
        direction: 'incoming',
      });
      actions.sendMessageStreamSuccess(responseMessage);
    }

    await delay(
      response.status === AskEnlilResponseStatus.Streaming
        ? MESSAGE_STREAM_DELAY
        : MESSAGE_FETCH_DELAY
    );

    return fetchAskEnlil({ ...options, messageSessionId: response.SessionUSID })
  }

  const sendInitialPrompt = async (message: string): Promise<boolean> => {
    actions.sendInitialPrompt();

    try {
      await fetchAskEnlil({ message });
      actions.sendInitialPromptSuccess();
      return true;
    } catch (error) {
      actions.sendInitialPromptError(String(error));
      return false;
    }
  }

  const sendMessage = async (message: string, contextAction?: string): Promise<boolean> => {
    const requestMessage = createMessage({ message, direction: 'outgoing' });
    actions.sendMessage(requestMessage);

    try {
      const { message_id: id, text } = await fetchAskEnlil({ message, contextAction });

      const responseMessage = createMessage({ id, message: text, direction: 'incoming' });
      actions.sendMessageSuccess(responseMessage);
      return true;
    } catch (error) {
      actions.sendMessageError(String(error));
      return false;
    }
  }

  return (
    <div className={classes.container}>
      <WidgetButton
        btnRef={btnRef}
        onClick={handleWidgetButtonClick}
      />
      <WidgetDialog
        isOpened={isOpened}
        messages={messages}
        isLoading={isLoading}
        onMessageSend={sendMessage}
        onClose={handleWidgetClose}
        onNewChat={handleNewChat}
        dialogPosition={dialogPosition}
      />
    </div>
  );
};

export default Widget;
export { WidgetProps } from './types';
