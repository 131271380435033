import React from 'react';
import { MessageList as OriginList, Message, TypingIndicator, MessageProps } from '@chatscope/chat-ui-kit-react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import cx from 'classnames';
import useStyles from './styles';
import { getMessageId } from '../../containers/Widget/helpers';

interface MessageListProps {
  messages: MessageProps[]
  showTypingIndicator?: boolean
}

const MessageList: React.FC<MessageListProps> = ({
  messages,
  showTypingIndicator,
}) => {
  const classes = useStyles();

  return (
    <OriginList
      className={classes.list}
      typingIndicator={showTypingIndicator && <TypingIndicator className={classes.typing} />}
    >
      {messages.map((message, i) => (
        <Message
          key={getMessageId(message) ?? i}
          className={cx(
            classes.message,
            message.model?.direction === 'outgoing' ? classes.outgoingMessage : classes.incomingMessage,
          )}
          {...message}
        >
          {message.type === 'text' && (
            <Message.TextContent>
              <Markdown remarkPlugins={[remarkGfm]}>
                {message.model?.message ?? ''}
              </Markdown>
            </Message.TextContent>
          )}
        </Message>
      ))}
    </OriginList>
  );
}

export default MessageList;
