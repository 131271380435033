import { createStyles, makeStyles } from '@material-ui/core';
import { Colors } from '../../constants';

export default makeStyles((theme) => createStyles({
  aiWidgetContainer: {
    position: 'absolute',
    right: 10,
    bottom: 10,
  },
  root: {
    fontFamily: 'Sora, sans-serif',
    pointerEvents: 'none',
  },
  paper: ({ widgetPosition }: { widgetPosition: any }) => ({
    position: 'absolute',
    bottom: 36,
    left: Number(widgetPosition?.left ?? 450) - 450,
    width: 420,
    height: 555,
    borderRadius: 11,
    pointerEvents: 'auto',
  }),
  dialogTitle: {
    background: `linear-gradient(45deg,${Colors.primary} 30%,${Colors.primaryGradient} 70%,${Colors.secondary} 90%)`,
    padding: theme.spacing(0, 2.5),
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 17,
    flexDirection: 'column',
    padding: theme.spacing(1, 0, 0),
  },
  titleLeftContainer: {
    color: Colors.white,
    fontSize: 17,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      height: 20,
      marginRight: theme.spacing(0.5),
    }
  },
  newButton: {
    backgroundColor: Colors.white,
    borderRadius: 100,
    width: 68,
    height: 26,
    fontSize: 12,
    fontWeight: 600,
    color: Colors.primary,
    lineHeight: 1,
    marginLeft: theme.spacing(2),

    '& svg': {
      height: 15,
      marginRight: theme.spacing(0.5),
    },

    '&:hover': {
      backgroundColor: Colors.white,
    },
  },
  closeButton: {
    marginRight: theme.spacing(-2),
    color: Colors.white,

    '& svg': {
      height: 24,
      width: 24,
    }
  },
  separator: {
    height: 1,
    backgroundColor: Colors.gray,
    border: 'none',
    marginTop: 0,
  },
  inputField: {
    display: 'flex',
    flexGrow: 1,
    '& .MuiOutlinedInput-root': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& input': {
      fontSize: 14,
    },
  },
  dialogActions: {
    padding: theme.spacing(0, 2, 1),
    justifyContent: 'flex-start',
    '& button': {
      backgroundColor: Colors.primary,
      color: Colors.white,
    },
    '& button:hover': {
      backgroundColor: Colors.primary,
    }
  },
  divider: {
    margin: theme.spacing(1, 2),
  },
  sendButton: {
    height: 30,
    width: 30,
    border: `1px solid ${Colors.lightGray}`,
    backgroundColor: Colors.primary,
    color: Colors.white,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: Colors.primary,
    },
    '& svg': {
      height: 13,
      width: 13,
    }
  },
  marginRight: {
    marginRight: theme.spacing(0.5),
  },
}));
