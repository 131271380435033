import { createStyles, makeStyles } from '@material-ui/core';
import { Colors } from '../../constants';

export default makeStyles((theme) => createStyles({
  button: {
    height: 60,
    width: 60,
    background: `linear-gradient(45deg,${Colors.primary} 30%,${Colors.primaryGradient} 70%,${Colors.secondary} 90%)`,
    color: Colors.white,
    zIndex: 1300,
    padding: theme.spacing(1.5),

    '& svg': {
      height: 24,
      width: 28,
    },
  },
  tooltip: {
    fontSize: 12,
  },
}));
