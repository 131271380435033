import { WidgetState } from "./types"

export const MESSAGE_FETCH_DELAY = 3000
export const MESSAGE_STREAM_DELAY = MESSAGE_FETCH_DELAY / 2

export const INIT_CHAT = 'INIT_CHAT' as const
export const UPDATE_ATTACHMENT_IDS = 'UPDATE_ATTACHMENT_IDS' as const
export const SEND_INITIAL_PROMPT = 'SEND_INITIAL_PROMPT' as const
export const SEND_INITIAL_PROMPT_SUCCESS = 'SEND_INITIAL_PROMPT_SUCCESS' as const
export const SEND_INITIAL_PROMPT_ERROR = 'SEND_INITIAL_PROMPT_ERROR' as const
export const SEND_MESSAGE = 'SEND_MESSAGE' as const
export const SEND_MESSAGE_STREAM_SUCCESS = 'SEND_MESSAGE_STREAM_SUCCESS' as const
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS' as const
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR' as const

export const DEFAULT_STATE: WidgetState = {
  isLoading: true,
  error: null,
  messages: [],
  chatSessionId: null,
}
